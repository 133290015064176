<template>
  <div>
    <b-row
      v-if="isValidToken && tokenChecked"
    >
      <b-col
        cols="12"
        lg="12"
      >
        <b-card
          v-if="relation.mollieMandateStatus === 2 && relation.mollieMandateType === 2"
          title=""
        >
          <b-row>
            <b-col cols="12">
              Geachte {{ relation.gender === 'm'? 'heer':'mevrouw' }} {{ relation.middleName?relation.middleName:'' }} {{ relation.lastName }},
              <br><br>
              Hartelijk dank voor uw verificatie van uw bankrekeningnummer.
              <br><br>
              Met vriendelijke groet,
              <br><br>
              HEM
            </b-col>
          </b-row>
        </b-card>
        <b-card
          v-if="!(relation.mollieMandateStatus === 2 && relation.mollieMandateType === 2)"
          title=""
        >
          <b-row>
            <b-col cols="12">
              Geachte {{ relation.gender === 'm'? 'heer':'mevrouw' }} {{ relation.middleName?relation.middleName:'' }} {{ relation.lastName }},
              <br><br>
              <p>Je hebt zojuist een overeenkomst getekend voor de levering van stroom en/of gas bij HEM en daar zijn wij ontzettend blij mee. Welkom! </p>
              <p>Omdat je hebt gekozen voor automatisch incasso hebben wij een machtiging nodig om je maandelijks te kunnen incasseren. Deze machtiging kan je afgeven door op onderstaande link te klikken en 0,01 te betalen ter verificatie. </p>
              <p>Met de keuze om middels een automatisch incasso te betalen machtig je HEM – dit is de handelsnaam van Allround Hollands Energie B.V. - om doorlopende incasso-opdrachten te versturen naar jouw bank, via Mollie. Hiervoor hanteren wij de standaard Europese incasso – SEPA en doen dit via onze payment provider Mollie. </p>
              <p>
                <b>Via onze payment provider Mollie</b>
              </p>
              <table>
                <tr>
                  <td>Naam:</td>
                  <td>&nbsp;</td>
                  <td>Mollie B.V.</td>
                </tr>
                <tr>
                  <td>Adres:</td>
                  <td>&nbsp;</td>
                  <td>Keizersgracht 126.</td>
                </tr>
                <tr>
                  <td>Naam:</td>
                  <td>&nbsp;</td>
                  <td>1015 CW</td>
                </tr>
                <tr>
                  <td>Woonplaats:</td>
                  <td>&nbsp;</td>
                  <td>Amsterdam</td>
                </tr>
                <tr>
                  <td>Land:</td>
                  <td>&nbsp;</td>
                  <td>Nederland</td>
                </tr>
                <tr>
                  <td>Incassant ID:</td>
                  <td>&nbsp;</td>
                  <td>NL08ZZZ502057730000</td>
                </tr>
                <tr>
                  <td>Kenmerk machtiging:</td>
                  <td>&nbsp;</td>
                  <td>Dit kenmerk vind u terug op uw welkomstbrief</td>
                </tr>
              </table>
              <br>

              <p>
                Door ondertekening van dit formulier geeft u toestemming aan Mollie B.V. om doorlopende incasso-opdrachten - met een SEPA, doorlopende machtiging - te sturen naar uw bank om een bedrag van uw rekening af te schrijven en aan uw bank om doorlopend een bedrag van uw rekening af te schrijven overeenkomstig de opdracht van Mollie B.V. Als u het niet eens bent met deze afschrijving kunt u deze laten terugboeken. Neem hiervoor binnen 8 weken na afschrijving contact op met uw bank. Vraag uw bank naar de voorwaarden.<br><br>
                De door ons gekozen incassant en het kenmerk van de machtiging wordt voor de eerste incasso-opdracht aan u meegedeeld.
              </p>

              <br>
              <b-button
                variant="primary"
                class="mr-1"
                :disabled="showSignAgreementLoader"
                style="border-radius:0.358rem;"
                @click="signMandate"
              >
                <b-spinner
                  v-if="showSignAgreementLoader"
                  small
                  class="mr-1"
                />
                <span class="align-middle">Onderteken machtiging</span>

              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="!isValidToken && tokenChecked">
      <b-col cols="12">
        <b-card
          title="Invalide token"
        >
         De link waarmee je deze pagina probeert te bezoeken is niet meer geldig en/of verlopen. Neem contact op met de klantenservice van HEM voor meer informatie. Op werkdagen te bereiken op 020 225 99 34 van 09:00 tot 17:00 uur.
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,

} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import offerStoreModule from '@/views/pages/offer/offerStoreModule'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, iban } from '@core/utils/validations/validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,

  },
  data() {
    return {
      tokenChecked: false,
      isValidToken: false,
      showSignAgreementLoader: false,
      token: '',
      offer: {},
      relation: {},
      url: null,
      showOverlay: true,
      page: 1,
      pageCount: 0,
      signatureCheck: true,
      pdf: null,
      required,
      iban,
    }
  },
  mounted() {
    this.fetchRelation()
  },
  methods: {
    fetchRelation() {
      this.token = this.$route.params.id
      store.dispatch('app-offer/fetchMandateRelation', {
        token: this.token,
      }).then(async response => {
        this.tokenChecked = true
        this.isValidToken = true
        this.relation = response.data
      }).catch(() => {
        this.tokenChecked = true
        this.isValidToken = false
      })
    },
    signMandate() {
      window.location.href = `https://api.hem.nl/v1/webhook/initmandate/${this.relation.id}/${this.relation.mollieCustomerID}`
    },
    error() {
      this.showOverlay = false
    },
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    validateSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/svg+xml')
      return { isEmpty, data }
    },
  },
  setup() {
    const OFFER_STORE_MODULE_NAME = 'app-offer'

    // Register module
    if (!store.hasModule(OFFER_STORE_MODULE_NAME)) store.registerModule(OFFER_STORE_MODULE_NAME, offerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OFFER_STORE_MODULE_NAME)) store.unregisterModule(OFFER_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
.signature {
  border: solid 2px #000000;
  border-radius: 5px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.signature.invalid {
  border: solid 2px #ea5455;
}

.invalid{
  .card-title{
    color: #ea5455
  }
}

.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
